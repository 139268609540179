import { ref } from "vue"
import publicApi from "../../../api/publicApi"




const useBanners = () => {

    const listadoBanners = ref()

    const getBanners = async () => {
        const { data } = await publicApi.get('/banners?populate[logo][fields]=url')
        listadoBanners.value = data.data.map( banner => {
            return {
                id: banner.id,
                url: banner.attributes.link,
                logo: banner.attributes.logo.data.attributes.url,
                nombre: banner.attributes.nombre
            }
        });
    }

    return {
        listadoBanners,
        getBanners,
    }

}

export default useBanners