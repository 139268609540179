<template>
    <div class="banners mt-2 mb-1">

        <swiper
            :slides-per-view="3"
            :loop="true"
            :space-between="20"
            :breakpoints="{
                '640': {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                '768': {
                    slidesPerView: 4,
                    spaceBetween: 40,
                },
                '1024': {
                    slidesPerView: 5,
                    spaceBetween: 50,
                },
            }"
            :modules="modules"
            class="banner-slider"
        >
            <swiper-slide v-for="banner in listadoBanners" :key="banner.id">
                <a :href="banner.url" target="_blank">
                    <div class="banner">
                        <div class="banner-img">
                            <img :src="banner.logo" />
                        </div>
                        <div class="texto">
                            {{banner.nombre}}
                        </div>
                    </div>
                </a>
            </swiper-slide>
        </swiper>
        
    </div>
</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { Pagination, Navigation } from "swiper"
import 'swiper/swiper.min.css'

import useBanners from '../../composables/useBanners';

export default {
    components: { Swiper, SwiperSlide },
    setup(){
        const { listadoBanners, getBanners } = useBanners()
        getBanners()
        return {
            modules: [ Pagination, Navigation ],
            listadoBanners
        }
    }

}
</script>

<style lang="sass" scoped>
.banner-slider
    width: 100%
    height: 130px
    .banner
        .banner-img
            img
                width: 90px
        .texto
            font-size: 0.7rem
            font-weight: 600
            color: #212121
</style>